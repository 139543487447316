<template>
  <div class="indexPage">
    <div class="swiperBox" id="swiper" :style="{ height: scrollerHeight }">
      <el-carousel>
        <el-carousel-item v-for="(item, index) in ppttrack" :key="index">
          <a :href="item.url" target="_blank" v-if="item.type == 2">
            <img
              class="bannImage"
              id="imagBox"
              :src="item.image"
              alt=""
              @click="addUrl(item)"
            />
          </a>
          <img
            v-else
            class="bannImage"
            id="imagBox"
            :src="item.image"
            alt=""
            @click="addUrl(item)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="albumBox" style="display: none">
      <div class="track_title">
        <h3 class="t_h3">三大赛道</h3>
        <h5 class="t_h5">THREE TYPES</h5>
      </div>
      <ul class="album_content clearfix">
        <li>
          <a
            href="https://www.ibeisai.com/PPThome?tagId=1006&tag=2&itemName=%E9%AB%98%E6%95%99%E4%B8%BB%E8%B5%9B%E9%81%93&type=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./../../assets/gaojiao.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.ibeisai.com/PPThome?tagId=1008&tag=2&itemName=%E8%81%8C%E6%95%99%E8%B5%9B%E9%81%93&type=1"
            target="_blank"
          >
            <img src="./../../assets/zhijiao.png" alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.ibeisai.com/PPThome?tagId=1007&tag=2&itemName=%E9%9D%92%E7%BA%A2%E6%97%85%E8%B5%9B%E9%81%93&type=1"
            target="_blank"
          >
            <img src="./../../assets/qinghong.png" alt="" />
          </a>
        </li>
      </ul>
    </div>
    <!-- 精选PPT推荐 -->
    <div class="album_PPT">
      <div class="track_title">
        <h3 class="t_h3">简历</h3>
        <h5 class="t_h5">RESUME</h5>
      </div>
      <!-- 选项卡 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in myData"
          :key="item.id"
          :label="item.name"
          :name="item.id.toString()"
        >
          <div class="ppt_bOX">
            <!-- <article class="ppt_post" v-for="(item,index) in artiList.slice(0,12)" :key="index"> -->
            <section v-if="isBoole">
              <article
                class="ppt_post"
                v-for="item in myData[0].page.rows"
                :key="item.laud"
              >
                <div class="ppt_inner" @click="jump(1, item)">
                  <div class="ppt_media-pic">
                    <i class="ppt_tag-vip ppt_tag-vips"></i>
                    <img
                      :src="item.cover"
                      width="600"
                      height="400"
                      alt=""
                      class="wp-ppt_post-image"
                    />
                    <a class="ppt_post-cate">
                      <em class="ppt_tag-213"> 演示版 </em>
                    </a>
                  </div>
                  <div class="clearfix">
                    <p class="ppt_post-date fl">
                      <span
                        class=""
                        v-for="(citem, cindex) in item.tag.split(',')"
                        :key="citem.code"
                      >
                        <span class="ppt_mix_back_span">
                          {{ citem }}
                        </span>
                      </span>
                    </p>
                  </div>
                  <div class="ppt_media-body clearfix">
                    <a class="ppt_title fl" :title="item.name">
                      <span
                        class="ppt_title_span"
                        v-show="item.animation != 0"
                        >{{
                          item.animation == 1
                            ? "路演"
                            : item.animation == 2
                            ? "网评"
                            : ""
                        }}</span
                      >
                      {{ item.name }}
                    </a>
                    <p class="ppt_post-meta fr">
                      <em>
                        <i class="iconfont iconai-eye"></i>{{ item.pv }}
                      </em>
                      <em>
                        <i class="iconfont iconaixin"></i>{{ item.laud }}
                      </em>
                    </p>
                  </div>
                </div>
              </article>
            </section>
            <!-- 分页数据 -->
            <section v-if="!isBoole">
              <!-- {{ newDataPage }} -->
              <article
                class="ppt_post"
                v-for="item in newDataPage"
                :key="item.laud"
              >
                <div class="ppt_inner" @click="jump(1, item)">
                  <div class="ppt_media-pic">
                    <i class="ppt_tag-vip ppt_tag-vips"></i>
                    <img
                      :src="item.cover"
                      width="600"
                      height="400"
                      alt=""
                      class="wp-ppt_post-image"
                    />
                    <a class="ppt_post-cate">
                      <em class="ppt_tag-213"> </em>
                    </a>
                  </div>
                  <div class="clearfix">
                    <p class="ppt_post-date fl">
                      <span
                        class=""
                        v-for="(citem, cindex) in item.tag.split(',')"
                        :key="cindex"
                      >
                        <span class="ppt_mix_back_span">
                          {{ citem }}
                        </span>
                      </span>
                    </p>
                  </div>
                  <div class="ppt_media-body clearfix">
                    <a class="ppt_title fl" :title="item.name">
                      <span
                        class="ppt_title_span"
                        v-show="item.animation != 0"
                        >{{
                          item.animation == 1
                            ? "路演"
                            : item.animation == 2
                            ? "网评"
                            : ""
                        }}</span
                      >
                      {{ item.name }}
                    </a>
                    <p class="ppt_post-meta fr">
                      <em>
                        <i class="iconfont iconai-eye"></i>{{ item.pv }}
                      </em>
                      <em>
                        <i class="iconfont iconaixin"></i>{{ item.laud }}
                      </em>
                    </p>
                  </div>
                </div>
              </article>
            </section>
            <!-- {{ newPage }} -->
            <!-- <article
                v-if="isPage"
              class="ppt_post"
              v-for="item in newPage"
              :key="item"
               
            >
              <div class="ppt_inner" @click="jump(1, item)">
                <div class="ppt_media-pic">
                  <i class="ppt_tag-vip ppt_tag-vips"></i>
                  <img
                    :src="item.cover"
                    width="600"
                    height="400"
                    alt=""
                    class="wp-ppt_post-image"
                  />
                  <a class="ppt_post-cate">
                    <em class="ppt_tag-213"> 演示版 </em>
                  </a>
                </div>
                <div class="clearfix">
                  <p class="ppt_post-date fl">
                    <span
                      class=""
                      v-for="(citem, cindex) in item.tag.split(',')"
                      :key="cindex"
                    >
                      <span class="ppt_mix_back_span">
                        {{ citem }}
                      </span>
                    </span>
                  </p>
                </div>
                <div class="ppt_media-body clearfix">
                  <a class="ppt_title fl" :title="item.name">
                    <span class="ppt_title_span" v-show="item.animation != 0">{{
                      item.animation == 1
                        ? "路演"
                        : item.animation == 2
                        ? "网评"
                        : ""
                    }}</span>
                    {{ item.name }}
                  </a>
                  <p class="ppt_post-meta fr">
                    <em> <i class="iconfont iconai-eye"></i>{{ item.pv }} </em>
                    <em> <i class="iconfont iconaixin"></i>{{ item.laud }} </em>
                  </p>
                </div>
              </div>
            </article> -->
          </div>
          <!-- 分页 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="totalItems"
            @current-change="newCurrent"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 简历 -->

    <div class="album_PPT">
      <div class="track_title">
        <h3 class="t_h3">发展报告</h3>
        <h5 class="t_h5">DEVEMOPEMNT REPORT</h5>
      </div>
      <!-- 选项卡 -->
      <el-tabs v-model="activeName1" @tab-click="handleClickResume">
        <el-tab-pane
          v-for="item in resumeData"
          :key="item.id"
          :label="item.name"
          :name="item.id.toString()"
        >
          <div class="ppt_bOX">
            <!-- <article class="ppt_post" v-for="(item,index) in artiList.slice(0,12)" :key="index"> -->
            <section v-if="isBooleResume">
              <article
                class="ppt_post"
                v-for="item in resumeData[0].page.rows"
                :key="item.laud"
              >
                <div class="ppt_inner" @click="jump(1, item)">
                  <div class="ppt_media-pic">
                    <i class="ppt_tag-vip ppt_tag-vips"></i>
                    <img
                      :src="item.cover"
                      width="600"
                      height="400"
                      alt=""
                      class="wp-ppt_post-image"
                    />
                    <a class="ppt_post-cate">
                      <em class="ppt_tag-213"> 演示版 </em>
                    </a>
                  </div>
                  <div class="clearfix">
                    <p class="ppt_post-date fl">
                      <span
                        class=""
                        v-for="(citem, cindex) in item.tag.split(',')"
                        :key="citem.code"
                      >
                        <span class="ppt_mix_back_span">
                          {{ citem }}
                        </span>
                      </span>
                    </p>
                  </div>
                  <div class="ppt_media-body clearfix">
                    <a class="ppt_title fl" :title="item.name">
                      <span
                        class="ppt_title_span"
                        v-show="item.animation != 0"
                        >{{
                          item.animation == 1
                            ? "路演"
                            : item.animation == 2
                            ? "网评"
                            : ""
                        }}</span
                      >
                      {{ item.name }}
                    </a>
                    <p class="ppt_post-meta fr">
                      <em>
                        <i class="iconfont iconai-eye"></i>{{ item.pv }}
                      </em>
                      <em>
                        <i class="iconfont iconaixin"></i>{{ item.laud }}
                      </em>
                    </p>
                  </div>
                </div>
              </article>
            </section>
            <!-- 分页数据 -->
            <section v-if="!isBooleResume">
              <!-- {{ newDataPage }} -->
              <article
                class="ppt_post"
                v-for="item in resumeNewDataPage"
                :key="item.laud"
              >
                <div class="ppt_inner" @click="jump(1, item)">
                  <div class="ppt_media-pic">
                    <i class="ppt_tag-vip ppt_tag-vips"></i>
                    <img
                      :src="item.cover"
                      width="600"
                      height="400"
                      alt=""
                      class="wp-ppt_post-image"
                    />
                    <a class="ppt_post-cate">
                      <em class="ppt_tag-213"> </em>
                    </a>
                  </div>
                  <div class="clearfix">
                    <p class="ppt_post-date fl">
                      <span
                        class=""
                        v-for="(citem, cindex) in item.tag.split(',')"
                        :key="cindex"
                      >
                        <span class="ppt_mix_back_span">
                          {{ citem }}
                        </span>
                      </span>
                    </p>
                  </div>
                  <div class="ppt_media-body clearfix">
                    <a class="ppt_title fl" :title="item.name">
                      <span
                        class="ppt_title_span"
                        v-show="item.animation != 0"
                        >{{
                          item.animation == 1
                            ? "路演"
                            : item.animation == 2
                            ? "网评"
                            : ""
                        }}</span
                      >
                      {{ item.name }}
                    </a>
                    <p class="ppt_post-meta fr">
                      <em>
                        <i class="iconfont iconai-eye"></i>{{ item.pv }}
                      </em>
                      <em>
                        <i class="iconfont iconaixin"></i>{{ item.laud }}
                      </em>
                    </p>
                  </div>
                </div>
              </article>
            </section>
            <!-- {{ newPage }} -->
            <!-- <article
                v-if="isPage"
              class="ppt_post"
              v-for="item in newPage"
              :key="item"
               
            >
              <div class="ppt_inner" @click="jump(1, item)">
                <div class="ppt_media-pic">
                  <i class="ppt_tag-vip ppt_tag-vips"></i>
                  <img
                    :src="item.cover"
                    width="600"
                    height="400"
                    alt=""
                    class="wp-ppt_post-image"
                  />
                  <a class="ppt_post-cate">
                    <em class="ppt_tag-213"> 演示版 </em>
                  </a>
                </div>
                <div class="clearfix">
                  <p class="ppt_post-date fl">
                    <span
                      class=""
                      v-for="(citem, cindex) in item.tag.split(',')"
                      :key="cindex"
                    >
                      <span class="ppt_mix_back_span">
                        {{ citem }}
                      </span>
                    </span>
                  </p>
                </div>
                <div class="ppt_media-body clearfix">
                  <a class="ppt_title fl" :title="item.name">
                    <span class="ppt_title_span" v-show="item.animation != 0">{{
                      item.animation == 1
                        ? "路演"
                        : item.animation == 2
                        ? "网评"
                        : ""
                    }}</span>
                    {{ item.name }}
                  </a>
                  <p class="ppt_post-meta fr">
                    <em> <i class="iconfont iconai-eye"></i>{{ item.pv }} </em>
                    <em> <i class="iconfont iconaixin"></i>{{ item.laud }} </em>
                  </p>
                </div>
              </div>
            </article> -->
          </div>
          <!-- 分页 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="resumeCurrentPage"
            :page-size="pageSize"
            :total="resumeTotalItems"
            @current-change="devenewCurrent"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 精品WORD推荐 -->
    <div class="diversions">
      <div class="banxin">
        <p class="banxin_title"></p>
        <div class="banxin_div">
          <vue-seamless-scroll
            :data="realData"
            :class-option="classOption1"
            ref="seamlessScroll"
            class="seamless-warp"
            style="padding-left: 30px"
          >
            <ul>
              <li
                class="bar_item fl"
                v-for="(item, index) in realData"
                :key="'r' + index"
                :data-index="index"
              >
                <div class="userImg">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="bar_detail" :title="item.content">
                  {{ item.content }}
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
          <vue-seamless-scroll
            :data="realData2"
            :class-option="classOption2"
            ref="seamlessScroll1"
            class="seamless-warp"
            style="padding-left: 60px"
          >
            <ul>
              <li
                class="bar_item fl"
                v-for="(items, index) in realData2"
                :key="'s' + index"
                :data-index="index"
              >
                <div class="userImg">
                  <img :src="items.avatar" alt="" />
                </div>
                <div class="bar_detail">
                  {{ items.content }}
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
          <vue-seamless-scroll
            :data="realData3"
            :class-option="classOption3"
            ref="seamlessScroll2"
            class="seamless-warp"
            style="padding-left: 90px"
          >
            <ul>
              <li
                class="bar_item fl"
                v-for="(item, index) in realData3"
                :key="'t' + index"
                :data-index="index"
              >
                <div class="userImg">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="bar_detail">
                  {{ item.content }}
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>

    <div class="itmentBox">
      <ul class="tment_ul clearfix">
        <li>
          <i class="iconfont iconbanquan"></i>
          <p class="decla_title">正版承诺</p>
          <p class="decla_intro">拒绝盗版，获设计师独家授权</p>
        </li>
        <li>
          <i class="iconfont iconbanquan1"></i>
          <p class="decla_title">版权保障 商用无忧</p>
          <p class="decla_intro">对全站所有模板均拥有商用授权</p>
        </li>
        <li class="last_item">
          <i class="iconfont icontiexinfuwu"></i>
          <p class="decla_title">全天候 贴心服务</p>
          <p class="decla_intro">
            发票支持，报销无忧<br />团队协作，院校会员可多人使用
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
// import nav from "../../store/moudels/nav";
export default {
  data() {
    return {
      isBooleResume: true,
      isBoole: true,
      newDataPage: [],
      // newPage:0,
      currentPage: 1, //当前页码
      pageSize: 8, //每页显示数量
      totalItems: 0, //总条目数
      realData: [],
      realData2: [],
      realData3: [],
      screenWidth: document.body.clientWidth,
      scrollerHeight: 678 + "px",
      ppttrack: [],
      artiList: [],
      wordList: [],
      myData: [], //首页数据
      activeName: "1614",
      activeName1: "1614",
      tagId: "1614",
      myId: [],
      userSelectId: "",
      resumeData: [],
      resumeTagId: "",
      resumeCurrentPage: 1,
      resumeTotalItems: 0,
      resumeNewDataPage: [],
    };
  },
  computed: {
    classOption1() {
      return {
        step: 1.2, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1200, // 单步运动停止的时间(默认值1000ms)
      };
    },
    classOption2() {
      return {
        step: 1.2, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1200, // 单步运动停止的时间(默认值1000ms)
      };
    },
    classOption3() {
      return {
        step: 1.2, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1200, // 单步运动停止的时间(默认值1000ms)
      };
    },
    ...mapState(["nav"]),
  },
  methods: {
    // logCurrentPage() {

    // },
    newCurrent(val) {
      this.currentPage = val;
      this.getSearchkey();
    },

    devenewCurrent(val) {
      this.resumeCurrentPage = val;
      this.getSearchResume();
    },
    // 获取2赛道首页数据
    getHome() {
      this.axios
        .indexVtwo({})
        .then((res) => {
          // this.page= res.data.data[0].page.rows;
          this.myData = res.data.data;
          this.tagId = this.myData[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获得简历部分导航
    getresumenav() {
      this.axios
        .indexVResume({})
        .then((res) => {
          this.resumeData = res.data.data;
          this.resumeTagId = this.realData[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getComment() {
      this.axios
        .indexComment({})
        .then((res) => {
          if (res.data.code == 0) {
            this.realData = res.data.data.s1;
            this.$refs.seamlessScroll.reset();
            this.realData2 = res.data.data.s2;
            this.$refs.seamlessScroll1.reset();
            this.realData3 = res.data.data.s3;
            this.$refs.seamlessScroll2.reset();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSwipers() {
      this.axios
        .indexSwiper({})
        .then((res) => {
          if (res.data.code == 0) {
            this.ppttrack = res.data.data;
            this.swiperBox();
          } else {
            this.ppttrack = [];
          }
        })
        .catch((err) => {});
    },
    handleClick(tab, event) {
      this.currentPage = 1;
      this.tagId = this.myData[tab.index].id;
      this.axios
        .searchkeyHome({
          id: this.tagId,
          pageNum: 1,
        })
        .then((res) => {
          this.isBoole = false;
          this.totalItems = res.data.data.total; //总条目数
          this.newDataPage = res.data.data.rows;
          this.$forceUpdate(); //强制刷新dom更新
        })
        .catch((err) => {});
    },
    getSearchkey() {
      // console.log("参数",this.tagId,"参数2",this.currentPage);
      this.axios
        .searchkeyHome({
          id: this.tagId,
          pageNum: this.currentPage,
        })

        .then((res) => {
          this.isBoole = false;

          this.totalItems = res.data.data.total; //总条目数
          this.newDataPage = res.data.data.rows;
          this.$forceUpdate(); //强制刷新dom更新
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleClickResume(tab, event) {
      console.log(tab);
      this.resumeCurrentPage = 1;
      this.resumeTagId = this.resumeData[tab.index].id;

      this.axios
        .searchkeyResume({
          id: this.resumeTagId,
          pageNum: 1,
        })
        .then((res) => {
          this.isBooleResume = false;
          this.resumeTotalItems = res.data.data.total; //总条目数
          this.resumeNewDataPage = res.data.data.rows;
          this.$forceUpdate(); //强制刷新dom更新
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    getSearchResume() {
      // console.log("参数",this.tagId,"参数2",this.currentPage);
      this.axios
        .searchkeyResume({
          id: this.resumeTagId,
          pageNum: this.resumeCurrentPage,
        })

        .then((res) => {
          this.isBoole = false;

          this.resumeTotalItems = res.data.data.total; //总条目数
          this.resumeNewDataPage = res.data.data.rows;
          this.$forceUpdate(); //强制刷新dom更新
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //     .catch((err) => {});
    //   this.axios
    //     .indexPage({})
    //     .then((res) => {
    //       if (res.data.code == 0) {
    //         this.artiList = res.data.data.ppt;
    //         this.wordList = res.data.data.word;
    //         this.swiperBox();
    //       }
    //     })
    //     .catch((err) => {});
    // },
    // getJob() {
    //   //获取首页评论
    //   this.axios
    //     .indexMsg({})
    //     .then((res) => {
    //       if (res.data.code == 0) {
    //         this.realData = res.data.data.s1;
    //         this.$refs.seamlessScroll.reset();
    //         this.realData2 = res.data.data.s2;
    //         this.$refs.seamlessScroll1.reset();
    //         this.realData3 = res.data.data.s3;
    //         this.$refs.seamlessScroll2.reset();
    //       }
    //     })
    //     .catch((err) => {});
    // },
    //跳转至详情页
    jump(val, item) {
      let link = this.$router.resolve({
        // name: "Detailspage",
        // zzh2
        name: "details",

        query: { type: val, code: item.code },
      });
      window.open(link.href, "_blank");
    },
    swiperBox() {
      const that = this;
      var height = document.getElementById("imagBox").offsetHeight;
      that.scrollerHeight = height + "px";
    },
    addUrl(item) {
      // console.log(item)
    },
    ...mapMutations(["UP_BOOLE"]),
  },
  watch: {
    screenWidth(val) {
      this.swiperBox();
    },
  },
  created() {
    // console.log(nav.boole);
    // this.getJob();
    // this.getapp();
  },
  async mounted() {
    // this.handleClick();
    await this.getSwipers();
    await this.getComment();
    await this.getHome();
    await this.getresumenav();
    await this.UP_BOOLE();
    await this.getSearchkey();
    await this.getSearchResume();
    // this.getSearchkey();
    // console.log(111);
    // this.getapp();
    this.$nextTick(() => {
      setTimeout(() => {
        this.swiperBox();
      }, 1000);
    });
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
};
</script>
<style scoped>
@import "./../../styles/Page/indexPage.css";
</style>
<style>
/* 选项卡 */
.el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 分页 */
.el-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.seamless-warp {
  height: 80px;
  margin-bottom: 17px;
  overflow: hidden;
}
.swiperBox .el-carousel__indicators {
  opacity: 0;
}
.swiperBox .el-carousel {
  width: 100%;
  overflow: hidden;
  height: 100%;
}
.swiperBox .el-carousel__container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>